<template>
  <v-container class="d-flex justify-center">
    <div>
      <img src="@/assets/404.svg" alt="404">
      <div class="text-h4 my-4" v-text="$t('404.sorry')" />
      <div class="text-h5 message mb-4" v-text="$t('404.message')" />
      <v-btn to="/" color="primary" class="mt-4" v-text="$t('404.backToHome')" />
    </div>
  </v-container>
</template>

<script>

export default {
  name: 'Page404',
}
</script>

<style lang="scss" scoped>
.container {
  max-width: 650px;
  padding-top: 0;
}

img {
  margin-top: -2px;
}

.message {
  max-width: 500px;
}
</style>
